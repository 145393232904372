import React from "react";
import { Box, Typography } from "@mui/material";
import service1 from "Images/new/service1.png";
import service2 from "Images/new/service2.png";
import service3 from "Images/new/service3.png";
import service4 from "Images/new/service4.png";
import service5 from "Images/new/service5.png";

const ServiceCard = ({ service }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "column",
        maxWidth: "420px",
        height: "208px",
        backgroundImage: `url(${service.image})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        borderRadius: "12px",
        m:"10px",
        p:"10px"
      }}
    >
      <Typography
        sx={{
          color: "black",
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        {service.title}
      </Typography>
      <Typography
        sx={{
          color: "#4B5563",
          fontSize: "16px",
        }}
      >
        {service.description}
      </Typography>
    </Box>
  );
};

function Services() {
  const isMobilePhone = window.innerWidth < 600;
  const services = [
    {
      title: "Vente de Jeux en Ligne",
      description:
        "Trouvez une large sélection de jeux pour tous les goûts et tous les âges.",
      image: service1,
    },
    {
      title: "Formation en Ligne (MOOC)",
      description:
        "Apprenez à jouer et maîtriser vos jeux préférés avec nos cours en ligne adaptés à tous les niveaux.",
      image: service2,
    },
    {
      title: "Organisation d'Événements",
      description:
        "Des événements personnalisés, privés ou publics, pour vivre des expériences uniques.",
      image: service3,
    },
    {
      title: "Tournois & Compétitions",
      description:
        "Affrontez d'autres joueurs dans nos tournois avec des prix à la clé.",
      image: service4,
    },
    {
      title: "Espace Communautaire",
      description:
        "Rejoignez notre communauté pour échanger et partager vos expériences autour des jeux.",
      image: service5,
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column",
      }}
      id="services"
    >
      <Typography
        sx={{
          color: "black",
          fontWeight: "bold",
          fontSize: "32px",
          textAlign: "center",
          margin: "60px 0 30px 0",
        }}
      >
        Nos Services
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {services.map((item, index) => (
          <ServiceCard key={index} service={item} />
        ))}
      </Box>
    </Box>
  );
}

export default Services;
