import refreshToken from "../helpers/functions";

const API = process.env.REACT_APP_API_URL;

//////////////////////////////////
// MOOC SERVICES
//////////////////////////////////

// Get all MOOCs
export const getAllMoocs = async () => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}moocs`);
    return response.data;
  } catch (error) {
    console.error("Error fetching MOOCs:", error);
    throw error;
  }
};

// Get MOOC details by ID
export const getMoocDetails = async (moocId) => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}moocs/${moocId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching MOOC details:", error);
    throw error;
  }
};

// Get MOOC participants
export const getMoocParticipants = async (moocId) => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}moocs/${moocId}/participants`);
    return response.data;
  } catch (error) {
    console.error("Error fetching MOOC participants:", error);
    throw error;
  }
};

// Get certified clients for MOOC
export const getCertifiedClients = async (moocId) => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}moocs/${moocId}/certified-clients`);
    return response.data;
  } catch (error) {
    console.error("Error fetching certified clients:", error);
    throw error;
  }
};

// Update MOOC details
export const updateMoocDetails = async (moocId, moocData) => {
  let http = await refreshToken();
  try {
    const response = await http.put(`${API}moocs/${moocId}`, moocData);
    return response.data;
  } catch (error) {
    console.error("Error updating MOOC details:", error);
    throw error;
  }
};

// Enroll in a MOOC
export const enrollInMooc = async (moocId, clientData) => {
  let http = await refreshToken();
  try {
    const response = await http.post(`${API}moocs/${moocId}/enroll`, clientData);
    return response.data;
  } catch (error) {
    console.error("Error enrolling in MOOC:", error);
    throw error;
  }
};

// Delete MOOC by ID
export const deleteMooc = async (moocId) => {
  let http = await refreshToken();
  try {
    const response = await http.delete(`${API}moocs/${moocId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting MOOC:", error);
    throw error;
  }
};

//////////////////////////////////
// LESSON SERVICES
//////////////////////////////////

// Get lessons for a specific MOOC
export const getMoocLessons = async (moocId) => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}moocs/${moocId}/lessons`);
    return response.data;
  } catch (error) {
    console.error("Error fetching MOOC lessons:", error);
    throw error;
  }
};

// Create a lesson for a specific MOOC
export const createLesson = async (moocId, lessonData) => {
  let http = await refreshToken();
  const formData = new FormData();
  lessonData.attachments.forEach((file) => {
    formData.append("attachments", file);
  });
  formData.append("lessonData", JSON.stringify(lessonData));

  try {
    const response = await http.post(`${API}moocs/${moocId}/lessons`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating lesson:", error);
    throw error;
  }
};

//////////////////////////////////
// FINAL TEST SERVICES
//////////////////////////////////

// Get final test for a specific MOOC
export const getFinalTestDetails = async (moocId) => {
  let http = await refreshToken();
  try {
    const response = await http.get(`${API}moocs/${moocId}/final-test`);
    return response.data;
  } catch (error) {
    console.error("Error fetching final test details:", error);
    throw error;
  }
};

// Create final test for a specific MOOC
export const createFinalTest = async (moocId, finalTestData) => {
  let http = await refreshToken();
  try {
    const response = await http.post(`${API}moocs/${moocId}/final-test`, finalTestData);
    return response.data;
  } catch (error) {
    console.error("Error creating final test:", error);
    throw error;
  }
};

// Update final test for a specific MOOC
export const updateMoocFinalTest = async (moocId, finalTestData) => {
  let http = await refreshToken();
  try {
    const response = await http.put(`${API}moocs/${moocId}/final-test`, finalTestData);
    return response.data;
  } catch (error) {
    console.error("Error updating final test:", error);
    throw error;
  }
};

// Delete final test for a specific MOOC
export const deleteMoocFinalTest = async (moocId) => {
  let http = await refreshToken();
  try {
    const response = await http.delete(`${API}moocs/${moocId}/final-test`);
    return response.data;
  } catch (error) {
    console.error("Error deleting final test:", error);
    throw error;
  }
};
