import { Box, Typography } from "@mui/material";
import Each from "Components/Common/Each";
import React from "react";
import stat1 from "Images/new/stat1.png";
import stat2 from "Images/new/stat2.png";
import stat3 from "Images/new/stat3.png";

const StatItem = ({ image, number, title }) => {
  return (
    <Box
      sx={{
        flex: "1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FAFAFA",
        borderLeft: "1px solid",
        borderLeftColor: "rgba(0, 0, 0, 0.07)",
      }}
    >
      <Box
        sx={{
          height: "64px",
          width: "64px",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Typography
        sx={{
          color: "black",
          fontSize: "48px",
          fontWeight: "500",
        }}
      >
        {number}
      </Typography>
      <Typography
        sx={{
          color: "#4B5563",
          fontSize: "22px",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

const Stats = () => {
  const stats = [
    {
      image: stat1,
      number: "15,000+",
      title: "Joueurs actifs",
    },
    {
      image: stat2,
      number: "2,500+",
      title: "Jeux disponibles",
    },
    {
      image: stat3,
      number: "4.8/5",
      title: "Note moyenne",
    },
  ];
  return (
    <Box
      sx={{
        height: {
          sm: "413px",
          xs: "800px",
        },
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
      }}
    >
      <Each
        of={stats}
        render={(option, index) => (
          <StatItem
            image={option.image}
            number={option.number}
            title={option.title}
          />
        )}
      />
    </Box>
  );
};

export default Stats;
