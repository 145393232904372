// reducers/gameReducer.js

import {
    FETCH_GAMES_REQUEST,
    FETCH_GAMES_SUCCESS,
    FETCH_GAMES_FAILURE,
    FETCH_GAME_DETAILS_REQUEST,
    FETCH_GAME_DETAILS_SUCCESS,
    FETCH_GAME_DETAILS_FAILURE,
    CREATE_GAME_REQUEST,
    CREATE_GAME_SUCCESS,
    CREATE_GAME_FAILURE,
    UPDATE_GAME_REQUEST,
    UPDATE_GAME_SUCCESS,
    UPDATE_GAME_FAILURE,
    DELETE_GAME_REQUEST,
    DELETE_GAME_SUCCESS,
    DELETE_GAME_FAILURE
  } from "../Actions/GamesActions";
  
  const initialState = {
    games: [],
    gameDetails: null,
    loading: false,
    error: null
  };
  
  const gameReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_GAMES_REQUEST:
      case FETCH_GAME_DETAILS_REQUEST:
      case CREATE_GAME_REQUEST:
      case UPDATE_GAME_REQUEST:
      case DELETE_GAME_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
  
      case FETCH_GAMES_SUCCESS:
        return {
          ...state,
          loading: false,
          games: action.payload,
          error: null
        };
  
      case FETCH_GAME_DETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
          gameDetails: action.payload,
          error: null
        };
  
      case CREATE_GAME_SUCCESS:
        return {
          ...state,
          loading: false,
          games: [...state.games, action.payload],
          error: null
        };
  
      case UPDATE_GAME_SUCCESS:
        return {
          ...state,
          loading: false,
          games: state.games.map((game) =>
            game.id === action.payload.id ? action.payload : game
          ),
          error: null
        };
  
      case DELETE_GAME_SUCCESS:
        return {
          ...state,
          loading: false,
          games: state.games.filter((game) => game.id !== action.payload),
          error: null
        };
  
      case FETCH_GAMES_FAILURE:
      case FETCH_GAME_DETAILS_FAILURE:
      case CREATE_GAME_FAILURE:
      case UPDATE_GAME_FAILURE:
      case DELETE_GAME_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };
  
      default:
        return state;
    }
  };
  
  export default gameReducer;
  