import {
  ArrowBackOutlined,
  BorderColorOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Builder from "Components/Common/Builder";
import ErrorText from "Components/Common/ErrorText";
import React, { useEffect,useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TextItem from "Components/Common/TextItem";
import GamePlacesList from "Components/Admin/Games/GamePlacesList";
import GameEventsList from "Components/Admin/Games/GameEventsList";
import { toast } from "react-toastify";
import { fetchGameDetails } from "../../../Redux/Actions/GamesActions";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import { useDispatch, useSelector } from "react-redux";

const GamesDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { gameId } = useParams();
  const [isLoading, setIsloading] = useState(false);
  const { gameDetails, loading, error } = useSelector((state) => state.game);

  const getGameLevelLabel = (level) => {
    const levelLabels = {
      easy: "Facile",
      medium: "Moyen",
      hard: "Difficile",
      expert: "Expert",
    };
    return levelLabels[level] || "Facile";
  };
  useEffect(() => {
    const fetchGame = async () => {
      try {
        await dispatch(fetchGameDetails(gameId));
      } catch (error) {
        toast.error(`Erreur : ${error.message}`);
      }
    };
    fetchGame();
  }, [gameId]);
  return (
    <Builder
      builder={() => {
        if (loading) {
          return <CustomCircularProgress />;
        } else if (error) {
          return <ErrorText text={"Une erreur s'est produite!"} />;
        } else if (gameDetails) {
          return (
            <Box>
              {/* Header */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    startIcon={
                      <ArrowBackOutlined
                        sx={{
                          color: "grey",
                          height: "15px",
                          width: "18px",
                        }}
                      />
                    }
                    sx={{
                      color: "grey",
                      textTransform: "none",
                      fontSize: "14px",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Retour
                  </Button>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    color={"secondary.light"}
                  >
                    {gameDetails.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <IconButton
                    sx={{
                      border: "1px solid",
                      borderRadius: "4px",
                      borderColor: "grey",
                    }}
                    onClick={() => {
                      navigate(`edit`);
                    }}
                  >
                    <BorderColorOutlined sx={{ color: "alt.main" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      border: "1px solid",
                      borderRadius: "4px",
                      borderColor: "grey",
                    }}
                    onClick={() => {}}
                  >
                    <DeleteOutline sx={{ color: "alt.main" }} />
                  </IconButton>
                </Box>
              </Box>
              {/* Body */}
              <Box
                sx={{
                  p: "20px",
                }}
              >
                {/* Details */}
                <Box
                  sx={{
                    p: "10px",
                    border: "1px solid black",
                  }}
                >
                  <Typography
                    sx={{
                      color: "grey",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    À propos
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "70px",
                      rowGap: "20px",
                      padding: "10px",
                      alignItems: "start",
                      justifyContent: "start",
                    }}
                  >
                    <TextItem title="Nom" subTitle={gameDetails.name} />
                    <TextItem
                      title="Niveau"
                      subTitle={getGameLevelLabel(gameDetails.level)}
                    />
                    <TextItem title="Description" subTitle={gameDetails.description} />
                    <TextItem
                      title="Prix"
                      subTitle={gameDetails.price ? gameDetails.price : "Gratuit"}
                    />
                  </Box>
                </Box>
                {/* games and reservations */}
                <Box
                  sx={{
                    height: "45vh",
                    display: "flex",
                    mt: "20px",
                    gap: "10px",
                  }}
                >
                  {/* games */}
                  <GamePlacesList gameId={gameDetails._id} />
                  {/* Evénément */}
                  <GameEventsList gameId={gameDetails._id} />
                </Box>
              </Box>
            </Box>
          );
        } else {
          return <ErrorText text={"Jeu n'existe pas"} />;
        }
      }}
    />
  );
};

export default GamesDetails;
