import { Box, Button, Typography } from "@mui/material";
import MoocCard from "Components/Admin/Moocs/MoocCard";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleGlobalLoading } from "../../../Redux/Actions/GlobalActions";
import { fetchAllMoocs } from "../../../Redux/Actions/MoocsActions";
import { deleteMooc } from "Services/Mooc.services";
import { toast } from "react-toastify";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import Builder from "Components/Common/Builder";
import ErrorText from "Components/Common/ErrorText";
import CustomDialog from "Components/Common/CustomDialog";

const MoocsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedMooc, setSelectedMooc] = useState(null);
  const { moocs, error } = useSelector((state) => state.mooc);

  useEffect(() => {
    const fetchMoocs = async () => {
      try {
        setIsLoading(true);
        await dispatch(fetchAllMoocs());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error(`Erreur : ${error.message}`);
      }
    };
    fetchMoocs();
  }, []);

  const handleDelete = async () => {
    try {
      dispatch(toggleGlobalLoading(true));
      await dispatch(deleteMooc(selectedMooc._id));
      dispatch(toggleGlobalLoading(false));
      setOpen(false);
      setSelectedMooc(null);
      toast.success("Jeu supprimé avec succès!");
    } catch (error) {
      dispatch(toggleGlobalLoading(false));
      toast.error(`Erreur : ${error.message}`);
    }
  };

  return (
    <>
      <Builder
        builder={() => {
          if (isLoading) {
            return <CustomCircularProgress />;
          } else if (error) {
            return <ErrorText text={"Une erreur s'est produite!"} />;
          } else {
            return (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: "20px 10px",
                    // position: "sticky",
                    // top: "0",
                    // zIndex: "1",
                  }}
                >
                  <Typography variant="h3" fontWeight="bold" color="secondary">
                    List des Moocs
                  </Typography>
                  <Button
                    sx={{
                      width: "100px",
                      fontSize: "16px",
                      fontWeight: "400",
                      backgroundColor: "alt.main",
                      textTransform: "none",
                      ":hover": {
                        backgroundColor: "alt[400]",
                      },
                    }}
                    onClick={() => navigate("/moocs/add")}
                  >
                    Ajouter
                  </Button>
                </Box>
                <Builder
                  builder={() => {
                    if (moocs && moocs.length > 0) {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {moocs.map((game) => (
                            <MoocCard
                              key={game._id}
                              game={game}
                              setSelectedGame={setSelectedMooc}
                              setOpen={setOpen}
                            />
                          ))}
                        </Box>
                      );
                    } else {
                      return <ErrorText text={"Aucun mooc trouvé!"} sx={{height:"80%"}} />;
                    }
                  }}
                />
              </>
            );
          }
        }}
      />
      <CustomDialog
        content={"Supprimer le mooc"}
        open={open}
        setOpen={setOpen}
        title={"Es-tu sûr ?"}
        onClick={handleDelete}
      />
    </>
  );
};

export default MoocsList;
