import { LOADING_TOGGLE } from "../Actions/GlobalActions";

const initialState = {
  isLoading: false,
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_TOGGLE:
      return {
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default globalReducer;
