import React from "react";
import { Box, Button, Rating, Typography } from "@mui/material";
import gameImage from "Images/new/game.png";
import { StarOutline } from "@mui/icons-material";

const FeaturedGameCard = ({ game }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "column",
        width: "300px",
        height: "400px",
      }}
    >
      <Box
        sx={{
          height: "50%",
          width: "100%",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          backgroundImage: `url(${game.image})`,
          backgroundSize: "fill",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Box
        sx={{
          width: "100%",
          paddingLeft: "20px",
        }}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: "20px",
            fontWeight: "600",
            textAlign: "start",
          }}
        >
          {game.name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "5px",
          }}
        >
          <Rating
            name="half-rating-read"
            precision={0.1}
            value={game.rate}
            readOnly
            emptyIcon={<StarOutline style={{ color: "grey" }} />}
          />
          <Typography
            sx={{
              color: "#4B5563",
              fontSize: "14px",
            }}
          >
            {game.rate}
          </Typography>
        </Box>
      </Box>
      <Button
        sx={{
          backgroundColor: "#5821B6",
          width: "80%",
          height: "40px",
          borderRadius: "8px",
          border: "none",
          textTransform: "none",
          fontSize: "16px",
          fontWeight: "500",
        }}
        onClick={() => {}}
      >
        Emprunter
      </Button>
    </Box>
  );
};
const FeaturedGames = () => {
  const featuredGames = [
    {
      name: "Catan",
      rate: 4.5,
      image: gameImage,
    },
    {
      name: "Catan",
      rate: 3.5,
      image: gameImage,
    },
    {
      name: "Catan",
      rate: 4.8,
      image: gameImage,
    },
    {
      name: "Catan",
      rate: 4,
      image: gameImage,
    },
  ];
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column",
      }}
      id="featuredGames"
    >
      <Typography
        sx={{
          color: "black",
          fontWeight: "bold",
          fontSize: "32px",
          textAlign: "center",
          margin: "60px 0 30px 0",
        }}
      >
        Jeux en vedette
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {featuredGames.map((item, index) => (
          <FeaturedGameCard key={index} game={item} />
        ))}
      </Box>
    </Box>
  );
};

export default FeaturedGames;
