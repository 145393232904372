// reducers/moocReducer.js

import {
    FETCH_MOOCS_REQUEST,
    FETCH_MOOCS_SUCCESS,
    FETCH_MOOCS_FAILURE,
    FETCH_MOOC_DETAILS_REQUEST,
    FETCH_MOOC_DETAILS_SUCCESS,
    FETCH_MOOC_DETAILS_FAILURE,
    CREATE_MOOC_REQUEST,
    CREATE_MOOC_SUCCESS,
    CREATE_MOOC_FAILURE,
    UPDATE_MOOC_REQUEST,
    UPDATE_MOOC_SUCCESS,
    UPDATE_MOOC_FAILURE,
    DELETE_MOOC_REQUEST,
    DELETE_MOOC_SUCCESS,
    DELETE_MOOC_FAILURE
  } from "../Actions/MoocsActions";
  
  const initialState = {
    moocs: [],
    moocDetails: null,
    loading: false,
    error: null
  };
  
  const moocReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_MOOCS_REQUEST:
      case FETCH_MOOC_DETAILS_REQUEST:
      case CREATE_MOOC_REQUEST:
      case UPDATE_MOOC_REQUEST:
      case DELETE_MOOC_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
  
      case FETCH_MOOCS_SUCCESS:
        return {
          ...state,
          loading: false,
          moocs: action.payload,
          error: null
        };
  
      case FETCH_MOOC_DETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
          moocDetails: action.payload,
          error: null
        };
  
      case CREATE_MOOC_SUCCESS:
        return {
          ...state,
          loading: false,
          moocs: [...state.moocs, action.payload],
          error: null
        };
  
      case UPDATE_MOOC_SUCCESS:
        return {
          ...state,
          loading: false,
          moocs: state.moocs.map((mooc) =>
            mooc.id === action.payload.id ? action.payload : mooc
          ),
          error: null
        };
  
      case DELETE_MOOC_SUCCESS:
        return {
          ...state,
          loading: false,
          moocs: state.moocs.filter((mooc) => mooc.id !== action.payload),
          error: null
        };
  
      case FETCH_MOOCS_FAILURE:
      case FETCH_MOOC_DETAILS_FAILURE:
      case CREATE_MOOC_FAILURE:
      case UPDATE_MOOC_FAILURE:
      case DELETE_MOOC_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };
  
      default:
        return state;
    }
  };
  
  export default moocReducer;
  