import React, { useState } from "react";
import eyeSlash from "../../Images/eye-slash.svg";
import eye from "../../Images/eye.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../Redux/Actions/AuthentificationActions";
import "./Login.css";
import leftImage from "Images/new/login_left.png";
import rightImage from "Images/new/login_right.png";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email obligatoire"),
  password: Yup.string().required("Mot de passe obligatoire"),
});

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleLogin = async (values) => {
    await dispatch(login(values))
      .then((result) => {
        if (result) {
          navigate("/");
        } else {
          setOpen(true);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la connexion", error);
      });
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Nom d'utilisateur ou mot de passe incorrect
        </Alert>
      </Snackbar>
      <div className="login-page">
        <img src={leftImage} alt="left login image" className="left-image" />
        <img src={rightImage} alt="right login image" className="right-image" />
        <div className="login-body">
          <div>
            <p className="welcome-title">Welcome back</p>
            <p className="sub-title">Sign in to continue to your account</p>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleLogin}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
              }) => (
                <div>
                  <div className="form-group">
                    <label htmlFor="email" className="login-label">
                      Email d'utilisateur
                    </label>
                    <input
                      type="text"
                      placeholder="Email d'utilisateur"
                      className="field-login"
                      id="email"
                      name="email"
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      value={values.email}
                    />
                    {errors.email && touched.email && (
                      <span className="errorText">{errors.email}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="password" className="login-label">
                      Mot de passe
                    </label>
                    <div className="password-input-container">
                      <div
                        className="password-toggle"
                        onClick={() => setPasswordHidden(!passwordHidden)}
                      >
                        <img src={passwordHidden ? eyeSlash : eye} alt="icon" />
                      </div>
                      <input
                        type={passwordHidden ? "password" : "text"}
                        placeholder="Mot de passe"
                        className="field-login"
                        id="password"
                        name="password"
                        onChange={handleChange("password")}
                        onBlur={handleBlur("password")}
                        value={values.password}
                      />
                    </div>
                    {errors.password && touched.password && (
                      <span className="errorText">{errors.password}</span>
                    )}
                  </div>
                  <div className="remember-me-container">
                    <div className="remember-me">
                      <input type="radio" id="rememberMe" name="rememberMe" />
                      <label htmlFor="rememberMe">Souviens-toi de moi</label>
                    </div>
                    <a href="/forgetPassword" className="forget-password-link">
                      Mot de passe oublié?
                    </a>
                  </div>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="button-login"
                  >
                    Se connecter
                  </button>
                </div>
              )}
            </Formik>
          </div>
          <div className="continue-container">
            <div className="continue-header">
              <div className="line" />
              <p className="continue-text">ou continuer avec</p>
              <div className="line" />
            </div>
            <div className="continue-options">
              <div className="continue-option">Google</div>
              <div className="continue-option">Facebook</div>
            </div>
          </div>
          <div className="no-account">
            <p className="no-account-text">Vous n'avez pas de compte ?</p>
            <a href="/register" className="no-account-link">S'inscrire</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
