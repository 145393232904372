import React from "react";
import { Box, Button, Typography } from "@mui/material";
import explore1 from "Images/new/explore1.png";
import explore2 from "Images/new/explore2.png";
import explore3 from "Images/new/explore3.png";
import { ArrowForwardIosOutlined } from "@mui/icons-material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ExploreCard = ({ explore }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        justifyContent: "space-around",
        flexDirection: "column",
        width: "400px",
        height: "284px",
        backgroundColor: "#F1E9FE",
        padding: "20px",
        borderRadius: "12px",
      }}
    >
      <Box
        sx={{
          height: "60px",
          width: "60px",
          backgroundImage: `url(${explore.image})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Typography
        sx={{
          color: "black",
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        {explore.title}
      </Typography>
      <Typography
        sx={{
          color: "#4B5563",
          fontSize: "16px",
          textAlign: "start",
        }}
      >
        {explore.description}
      </Typography>
      <Button
        sx={{
          color: "#6928D9",
          width: "125px",
          height: "24px",
          border: "none",
          textTransform: "none",
          fontSize: "16px",
          fontWeight: "500",
          display: { xs: "none", sm: "inherit" },
        }}
        onClick={() => {}}
        endIcon={<ArrowForwardIosOutlined />}
      >
        Explorer
      </Button>
    </Box>
  );
};

function Explores() {
  const isMobilePhone = window.innerWidth < 600;
  const explores = [
    {
      title: "Jeux de société",
      description:
        "Découvrez notre collection de jeux de société modernes et classiques",
      image: explore1,
    },
    {
      title: "Jeux vidéo",
      description: "Plongez dans le monde passionnant des jeux vidéo",
      image: explore2,
    },
    {
      title: "Jeux de cartes",
      description:
        "Développez votre esprit stratégique avec nos jeux de réflexion",
      image: explore3,
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column",
      }}
      id="explores"
    >
      <Typography
        sx={{
          color: "black",
          fontWeight: "bold",
          fontSize: "32px",
          textAlign: "center",
          margin: "60px 0 30px 0",
        }}
      >
        Explorez Nos Univers
      </Typography>
      <Box
        sx={{
          width: "100%",
          ".dot": {
            backgroundColor: "#6928D9 !important",
          },
        }}
      >
        <Carousel
          infiniteLoop={true}
          autoPlay
          interval={3000}
          emulateTouch
          centerMode={true}
          centerSlidePercentage={isMobilePhone ? 95 : 40}
        >
          {explores.map((item, index) => (
            <ExploreCard key={index} explore={item} />
          ))}
        </Carousel>
      </Box>
    </Box>
  );
}

export default Explores;
