import { Box, Button } from "@mui/material";
import React from "react";
import logo from "Images/logo.png";
import { useNavigate } from "react-router-dom";
import CustomText from "Components/Common/CustomText";

const GameCard = ({ game, setSelectedGame, setOpen }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        boxShadow: 1,
        borderRadius: 1,
        width: "210px",
        height: "260px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "10px",
        padding: "10px",
        ":hover": {
          boxShadow: 3,
        },
        cursor: "pointer",
      }}
      onClick={() => navigate(`/games/${game._id}`)}
    >
      <img
        src={logo}
        alt={game.name}
        style={{
          width: "100%",
          height: "100px",
          objectFit: "cover",
          boxShadow: 1,
          borderRadius: "14px",
          border: "1px solid #f0f0f0",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 1,
          marginTop: 1,
        }}
      >
        <CustomText
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
            WebkitLineClamp: "2",
          }}
          text={game.name}
        />
        <CustomText
          sx={{
            fontSize: "12px",
            textAlign: "center",
            WebkitLineClamp: "2",
          }}
          text={game.description}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          marginTop: 1,
        }}
      >
        <Button
          sx={{
            flex: 1,
            bgcolor: "error.main",
            fontSize: "14px",
            fontWeight: "400",
            textTransform: "none",
            padding: "5px 15px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedGame(game._id);
            setOpen(true);
          }}
        >
          Supprimer
        </Button>
        <Button
          sx={{
            flex: 1,
            bgcolor: "alt.main",
            fontSize: "14px",
            fontWeight: "400",
            textTransform: "none",
            padding: "5px 15px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/games/${game._id}/edit`);
          }}
        >
          Modifier
        </Button>
      </Box>
    </Box>
  );
};

export default GameCard;
