import { Box, Typography } from "@mui/material";
import React from "react";
import logo from "Images/logo.png";
import {
  FacebookOutlined,
  Instagram,
  LinkedIn,
  X,
  YouTube,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

function Footer() {
  const isMobilePhone = window.innerWidth < 600;
  const links = [
    {
      icon: <FacebookOutlined color="primary" />,
      link: "https://www.facebook.com",
    },
    {
      icon: <YouTube color="primary" />,
      link: "https://www.facebook.com",
    },
    {
      icon: <Instagram color="primary" />,
      link: "https://www.facebook.com",
    },
    {
      icon: <LinkedIn color="primary" />,
      link: "https://www.facebook.com",
    },
    {
      icon: <X color="primary" />,
      link: "https://www.facebook.com",
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        height: {
          sm: "50vh",
        },
        backgroundColor: "#4c1d95",
        padding: "20px",
      }}
    >
      {/* content */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          width: "100%",
          height: "100%",
          gap: "50px",
          pt: "50px",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "20%",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                height: "60px",
                width: "60px",
                backgroundImage: `url(${logo})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                borderRadius: "50%",
              }}
            />
            <Typography
              sx={{
                color: "white",
                fontSize: "32px",
                fontWeight: "bold",
              }}
            >
              Prêt à jouer
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: "16px",
              color: "#dadada",
            }}
          >
            Jeux de société, jeux vidéo, événements ludiques, MOOC et plus
            encore. Explorez, apprenez et amusez-vous avec nous !
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "24px",
              fontWeight: "600",
              mb: "10px",
            }}
          >
            Liens rapides
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#dcdcdc",
            }}
          >
            À propos de nous
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#dcdcdc",
            }}
          >
            FAQ
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#dcdcdc",
            }}
          >
            Blog
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#dcdcdc",
            }}
          >
            Contact
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#dcdcdc",
            }}
          >
            Politique de confidentialité
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "24px",
              fontWeight: "600",
              mb: "10px",
            }}
          >
            Contact
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#dcdcdc",
            }}
          >
            contact@pretajouer.fr
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#dcdcdc",
            }}
          >
            21 rue des quatre vent garches
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "24px",
              fontWeight: "600",
            }}
          >
            Connecter
          </Typography>
          <Box
            sx={{
              flex: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "30px",
            }}
          >
            {links.map((item, index) => {
              return (
                <Link key={index} to={item.link}>
                  {item.icon}
                </Link>
              );
            })}
          </Box>
        </Box>
      </Box>
      {/* CopyRight */}
      <Typography
        sx={{
          fontSize: "14px",
          textAlign: "center",
          width: "90%",
          color: "#898989",
          borderTop: "1px solid",
          borderTopColor: "#1f937",
          pt: "20px",
        }}
      >
        Prêtàjouer @ 2024. Tous droits réservés.
      </Typography>
    </Box>
  );
}

export default Footer;
