import { Box, Typography } from "@mui/material";
import React from "react";
import image from "Images/new/about.png";

const About = () => {
  return (
    <Box
      sx={{
        height: {
          sm: "100vh",
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      id="about"
    >
      <Box
        sx={{
          flex: 1,
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "100%",
          display: {
            xs: "none",
            sm: "block",
          },
        }}
      />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
          padding: "90px",
        }}
      >
        <Typography
          sx={{
            fontSize: "40px",
            color: "black",
            fontWeight: "500",
          }}
        >
          À propos de nous
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: "#3c3c3c",
          }}
        >
          Chez Prêt à Jouer, nous simplifions et enrichissons le temps de jeu
          entre parents et enfants. Notre mission est de transformer chaque
          moment ludique en une expérience éducative et amusante.
          <br />
          <br />
          Recommandations sur mesure : Découvrez des jeux parfaitement adaptés
          aux besoins et préférences de votre enfant grâce à notre système
          intelligent. Tutoriels interactifs : Apprenez rapidement les règles et
          astuces de chaque jeu avec nos guides faciles à suivre. Espaces dédiés
          : Rejoignez des lieux spécialement conçus pour jouer, partager et
          créer des souvenirs inoubliables en famille. Rejoignez Prêt à Jouer et
          vivez des moments de complicité uniques avec vos enfants.
        </Typography>
      </Box>
    </Box>
  );
};

export default About;
