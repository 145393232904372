import React, { useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import PrivateRoute from "./Routes/PrivateRoute";
import AdminRoute from "./Routes/AdminRoute";
import LandingPage from "./Pages/LandingPage/LandingPage";
import Login from "./Pages/Authentification/Login";
import ForgetPassword from "./Pages/Authentification/ForgetPassword";
import ResetPassword from "./Pages/Authentification/ResetPassword";
import Register from "./Pages/Authentification/Register";

import { ThemeProvider } from "@emotion/react";
import { createTheme, CssBaseline } from "@mui/material";
import { themeSettings } from "theme";
import NotFoundError from "Components/Common/NotFoundError";
import Builder from "Components/Common/Builder";
import ClientRoute from "Routes/ClientRoute";
import LoadingOverlay from "Components/Common/LoadingOverlay";
const ROLES = {
  ADMIN: "ADMIN",
  CLIENT: "CLIENT",
  COLLABORATOR: "COLLABORATOR",
  COMPANY: "COMPANY",
};
dayjs.locale("fr");
function App() {
  const theme = useMemo(() => createTheme(themeSettings()), []);
  const user = useSelector((state) => state.authentification?.user);
  const token = useSelector((state) => state.authentification.userToken);
  const { isLoading } = useSelector((state) => state.global);
  console.log("user", user, "token", token);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {/* <Route
          path="/"
          element={
            <Builder
              builder={() => {
                if (token && user) {
                  if (user.role === "ADMIN") {
                    return (
                      <PrivateRoute requiredRoles={ROLES.ADMIN}>
                        <AdminRoute />
                      </PrivateRoute>
                    );
                  } else {
                    if (user.role === "CLIENT") {
                      return (
                        <PrivateRoute requiredRoles={ROLES.CLIENT}>
                          <ClientRoute />
                        </PrivateRoute>
                      );
                    }
                  }
                }
                return <LandingPage />;
              }}
            />
          }
        /> */}
        <Route path="/404" element={<NotFoundError />} />
        <Route
          path="/login"
          element={token && user ? <Navigate to="/" /> : <Login />}
        />
        <Route
          path="/register"
          element={token && user ? <Navigate to="/" /> : <Register />}
        />
        <Route
          path="/forgetPassword"
          element={token && user ? <Navigate to="/" /> : <ForgetPassword />}
        />
        <Route
          path="/password-reset/:userId/:token"
          element={token && user ? <Navigate to="/" /> : <ResetPassword />}
        />
        <Route
          path="/*"
          element={
            <Builder
              builder={() => {
                if (token && user) {
                  if (user.role === "ADMIN") {
                    return (
                      <PrivateRoute requiredRoles={[ROLES.ADMIN]}>
                        <AdminRoute />
                      </PrivateRoute>
                    );
                  } else {
                    if (user.role === "CLIENT") {
                      return (
                        <PrivateRoute requiredRoles={[ROLES.CLIENT]}>
                          <ClientRoute />
                        </PrivateRoute>
                      );
                    }
                  }
                }
                return <LandingPage />;
              }}
            />
          }
        />
      </Routes>
      <LoadingOverlay open={isLoading} />
    </ThemeProvider>
  );
}

export default App;
