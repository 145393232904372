// actions/moocActions.js

import { createMooc } from "Services/Games.services";
import {
  getAllMoocs,
  getMoocDetails,
  updateMoocDetails,
  deleteMooc,
} from "../../Services/Mooc.services";

// Action Types
export const FETCH_MOOCS_REQUEST = "FETCH_MOOCS_REQUEST";
export const FETCH_MOOCS_SUCCESS = "FETCH_MOOCS_SUCCESS";
export const FETCH_MOOCS_FAILURE = "FETCH_MOOCS_FAILURE";

export const FETCH_MOOC_DETAILS_REQUEST = "FETCH_MOOC_DETAILS_REQUEST";
export const FETCH_MOOC_DETAILS_SUCCESS = "FETCH_MOOC_DETAILS_SUCCESS";
export const FETCH_MOOC_DETAILS_FAILURE = "FETCH_MOOC_DETAILS_FAILURE";

export const CREATE_MOOC_REQUEST = "CREATE_MOOC_REQUEST";
export const CREATE_MOOC_SUCCESS = "CREATE_MOOC_SUCCESS";
export const CREATE_MOOC_FAILURE = "CREATE_MOOC_FAILURE";

export const UPDATE_MOOC_REQUEST = "UPDATE_MOOC_REQUEST";
export const UPDATE_MOOC_SUCCESS = "UPDATE_MOOC_SUCCESS";
export const UPDATE_MOOC_FAILURE = "UPDATE_MOOC_FAILURE";

export const DELETE_MOOC_REQUEST = "DELETE_MOOC_REQUEST";
export const DELETE_MOOC_SUCCESS = "DELETE_MOOC_SUCCESS";
export const DELETE_MOOC_FAILURE = "DELETE_MOOC_FAILURE";

// Fetch all moocs
export const fetchAllMoocs = () => async (dispatch) => {
  dispatch({ type: FETCH_MOOCS_REQUEST });
  try {
    const moocs = await getAllMoocs();
    dispatch({ type: FETCH_MOOCS_SUCCESS, payload: moocs });
  } catch (error) {
    dispatch({ type: FETCH_MOOCS_FAILURE, error });
    throw error;
  }
};

// Fetch mooc details by ID
export const fetchMoocDetails = (moocId) => async (dispatch) => {
  dispatch({ type: FETCH_MOOC_DETAILS_REQUEST });
  try {
    const mooc = await getMoocDetails(moocId);
    dispatch({ type: FETCH_MOOC_DETAILS_SUCCESS, payload: mooc });
  } catch (error) {
    dispatch({ type: FETCH_MOOC_DETAILS_FAILURE, error });
    throw error;
  }
};

// Create a new mooc
export const createNewMooc = (gameId,moocData) => async (dispatch) => {
  dispatch({ type: CREATE_MOOC_REQUEST });
  try {
    const newMooc = await createMooc(gameId,moocData);
    dispatch({ type: CREATE_MOOC_SUCCESS, payload: newMooc });
  } catch (error) {
    dispatch({ type: CREATE_MOOC_FAILURE, error });
    throw error;
  }
};

// Update mooc details
export const updateMooc = (moocId, moocData) => async (dispatch) => {
  dispatch({ type: UPDATE_MOOC_REQUEST });
  try {
    const updatedMooc = await updateMoocDetails(moocId, moocData);
    dispatch({ type: UPDATE_MOOC_SUCCESS, payload: updatedMooc });
  } catch (error) {
    dispatch({ type: UPDATE_MOOC_FAILURE, error });
    throw error;
  }
};

// Delete a mooc by ID
export const deleteMoocById = (moocId) => async (dispatch) => {
  dispatch({ type: DELETE_MOOC_REQUEST });
  try {
    await deleteMooc(moocId);
    dispatch({ type: DELETE_MOOC_SUCCESS, payload: moocId });
  } catch (error) {
    dispatch({ type: DELETE_MOOC_FAILURE, error });
    throw error;
  }
};
