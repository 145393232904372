import React from "react";
import { Box, Button, Typography } from "@mui/material";
import bg from "Images/new/welcome.png";

function Welcome() {
  return (
    <Box
      id="welcome"
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Typography
        sx={{
          color: "white",
          fontSize: {
            xs:"32px",
            sm:"56px"
          },
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        Découvrir, Emprunter, Jouer
      </Typography>
      <Typography
        sx={{
          color: "white",
          fontSize: "24px",
          textAlign: "center",
        }}
      >
        Explorez des jeux personnalisés et adaptés à votre enfant !
      </Typography>
      <Button
        sx={{
          backgroundColor: "#6C4BF5",
          color: "white",
          width: "225px",
          height: "45px",
          border: "none",
          borderRadius: "30px",
          textTransform: "none",
          fontSize: "14px",
          mt: "25px",
        }}
        onClick={() => {}}
      >
        Explorez nos recommandations
      </Button>
    </Box>
  );
}

export default Welcome;
