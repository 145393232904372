import { Box, Typography } from "@mui/material";
import React from "react";
import image from "Images/new/contact.png";
import { Formik } from "formik";
import * as Yup from "yup";

const Contact = () => {
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Prénom obligatoire"),
    last_name: Yup.string().required("Nom obligatoire"),
    email: Yup.string().required("Email obligatoire"),
    message: Yup.string().required("Message obligatoire"),
  });
  const onSubmit = () => {};
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "#7C3AED",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
      }}
      id="contact"
    >
      <Box
        sx={{
          height: "100%",
          flex: "1",
          position: "relative",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            height: "70%",
            width: "70%",
            top: "20%",
          }}
        />
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            p: "80px 40px",
            position: "relative",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "white",
                fontWeight: "bold",
                fontSize: "48px",
              }}
            >
              Contactez-nous
            </Typography>
            <Typography
              sx={{
                color: "#eee",
                fontSize: "16px",
              }}
            >
              Que vous recherchiez des partenariats, du financement ou des
              conseils d'experts, nous sommes là pour vous aider. Échangeons et
              concrétisons vos projets !
            </Typography>
            <Typography
              sx={{
                color: "#e5e5e5",
                fontSize: "14px",
                mt: "20px",
              }}
            >
              +3345443799
            </Typography>
            <Typography
              sx={{
                color: "#e5e5e5",
                fontSize: "14px",
              }}
            >
              contact@pretajouer.fr
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box flex={1}>
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Service client
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "white",
                  mt: "10px",
                }}
              >
                Notre équipe de support client est là pour vous aider à répondre
                à toutes vos questions ou préoccupations.
              </Typography>
            </Box>
            <Box flex={1}>
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Commentaires et suggestions
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "white",
                  mt: "10px",
                }}
              >
                Vos commentaires sont extrêmement importants pour nous et nous
                nous efforçons constamment d'améliorer Prêt à jouer.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: "100%",
          flex: "1",
          padding: "20px",
        }}
      >
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            message: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => (
            <Box
              sx={{
                height: "100%",
                backgroundColor: "white",
                borderRadius: "20px",
                padding: "20px 50px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "23px",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  Entrer en contact
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Prénom"
                    style={{
                      padding: "15px",
                      fontSize: "14px",
                      border: "1px solid #e5e7eb",
                      borderRadius: "8px",
                    }}
                    id="first_name"
                    name="first_name"
                    onChange={handleChange("first_name")}
                    onBlur={handleBlur("first_name")}
                    value={values.first_name}
                  />
                  {errors.first_name && touched.first_name && (
                    <span className="errorText">{errors.first_name}</span>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Nom"
                    style={{
                      padding: "15px",
                      fontSize: "14px",
                      border: "1px solid #e5e7eb",
                      borderRadius: "8px",
                    }}
                    id="last_name"
                    name="last_name"
                    onChange={handleChange("last_name")}
                    onBlur={handleBlur("last_name")}
                    value={values.last_name}
                  />
                  {errors.last_name && touched.last_name && (
                    <span className="errorText">{errors.last_name}</span>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Email"
                    style={{
                      padding: "15px",
                      fontSize: "14px",
                      border: "1px solid #e5e7eb",
                      borderRadius: "8px",
                    }}
                    id="email"
                    name="email"
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                    value={values.email}
                  />
                  {errors.email && touched.email && (
                    <span className="errorText">{errors.email}</span>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <textarea
                    placeholder="Comment pouvons-nous vous aider?"
                    style={{
                      padding: "15px",
                      fontSize: "14px",
                      border: "1px solid #e5e7eb",
                      borderRadius: "8px",
                    }}
                    id="message"
                    name="message"
                    onChange={handleChange("message")}
                    onBlur={handleBlur("message")}
                    value={values.message}
                    rows={7}
                  ></textarea>
                  {errors.message && touched.message && (
                    <span className="errorText">{errors.message}</span>
                  )}
                </div>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="button-login"
                >
                  Continuer
                </button>
                <p
                  style={{
                    fontSize: "13px",
                    color: "#666",
                    textAlign: "center",
                  }}
                >
                  En nous contactant, vous acceptez nos{" "}
                  <a href="">conditions d'utilisation</a> et notre{" "}
                  <a href="">politique de confidentialité</a>.
                </p>
              </div>
            </Box>
          )}
        </Formik>
      </Box>
      {/* <PopUp open={addSuccessOpen} setOpen={setAddSuccessOpen}>
        <AddSuccessPopUp
        onClick={() => setAddSuccessOpen(false)}
        title={"Email envoyé avec succès"}
        />
      </PopUp> */}
      {/* <LoadingOverlay open={addAlertLoading} /> */}
    </Box>
  );
};

export default Contact;
