import { MenuOutlined, PersonOutlined } from "@mui/icons-material";

import {
  Divider,
  Drawer,
  IconButton,
  List,
  Toolbar,
  Box,
  Button,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import logo from "Images/new/logo.png";
import "./LandingPage.css";

function NavItem({ text, link, color }) {
  return (
    <a href={`${link}`} style={{ textDecoration: "none" }}>
      <Typography
        sx={{
          color: "#575756",
          fontSize: "14px",
          fontWeight: "600",
          textTransform: "none",
          p: "5px 10px",
          textAlign: "start",
        }}
      >
        {text}
      </Typography>
    </a>
  );
}

function TopBar() {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const navItems = [
    { text: "Jeux", link: "#" },
    { text: "Événements", link: "#" },
    { text: "MOOCs", link: "#" },
    { text: "Communauté", link: "#" },
    { text: "Espace", link: "#" },
  ];
  const getColor = (item, index) => {
    const links = navItems.map((item) => item.link);
    if (index === 0 && !links.includes(window.location.href.split("#")[1])) {
      return "blue";
    }
    if (window.location.href.includes(item.link)) {
      return "blue";
    }
    return "#575756";
  };
  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "5px",
      }}
    >
      {/* Navigation */}
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Toolbar
          sx={{
            height: "80px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "white",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuOutlined
              sx={{
                color: "black",
              }}
            />
          </IconButton>
          <div className="logo">
            <img
              src={logo}
              alt="logo"
              style={{
                height: "40px",
              }}
            />
            <Typography
              sx={{
                color: "black",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Prêt à jouer
            </Typography>
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item, index) => (
                <Button key={item} sx={{ color: "#fff" }}>
                  <NavItem
                    key={index}
                    text={item.text}
                    link={item.link}
                    index={index}
                    color={getColor(item, index)}
                  />
                </Button>
              ))}
            </Box>
            <Button
              sx={{
                backgroundColor: "#6C4BF5",
                color: "white",
                width: "117px",
                height: "40px",
                border: "none",
                borderRadius: "30px",
                textTransform: "none",
                fontSize: "14px",
              }}
              startIcon={
                <PersonOutlined
                  sx={{
                    color: "white",
                    fontSize: "20px",
                  }}
                />
              }
              onClick={() => navigate("/login")}
            >
              Log In
            </Button>
          </Box>
        </Toolbar>
      </Box>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "200px",
              backgroundColor: "white",
            },
          }}
        >
          <Box
            onClick={handleDrawerToggle}
            sx={{ textAlign: "center",}}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: "24px",
                fontWeight: "bold",
                p: "10px",
              }}
            >
              Prêt à jouer
            </Typography>
            <Divider />
            <List>
              {navItems.map((item, index) => (
                <NavItem
                  key={index}
                  text={item.text}
                  link={item.link}
                  index={index}
                  color={getColor(item, index)}
                />
              ))}
            </List>
          </Box>
        </Drawer>
      </nav>
      {/* Login Button */}
    </Box>
  );
}
export default TopBar;
