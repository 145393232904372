import { Box, Button, Typography } from "@mui/material";
import GameCard from "Components/Admin/Games/GameCard";
import Builder from "Components/Common/Builder";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import ErrorText from "Components/Common/ErrorText";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchAllGames } from "../../../Redux/Actions/GamesActions";
import CustomDialog from "../../../Components/Common/CustomDialog";
import { deleteGame } from "Services/Games.services";
import { toggleGlobalLoading } from "../../../Redux/Actions/GlobalActions";

const GamesList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const { games, error } = useSelector((state) => state.game);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        setIsLoading(true);
        await dispatch(fetchAllGames());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error(`Erreur : ${error.message}`);
      }
    };
    fetchGames();
  }, []);

  const handleDelete = async () => {
    try {
      dispatch(toggleGlobalLoading(true));
      await dispatch(deleteGame(selectedGame._id));
      dispatch(toggleGlobalLoading(false));
      setOpen(false);
      setSelectedGame(null);
      toast.success("Jeu supprimé avec succès!");
    } catch (error) {
      dispatch(toggleGlobalLoading(false));
      toast.error(`Erreur : ${error.message}`);
    }
  };

  return (
    <>
      <Builder
        builder={() => {
          if (isLoading) {
            return <CustomCircularProgress />;
          } else if (error) {
            return <ErrorText text={"Une erreur s'est produite!"} />;
          } else {
            return (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: "20px 10px",
                    // position: "sticky",
                    // top: "0",
                    // zIndex: "1",
                  }}
                >
                  <Typography variant="h3" fontWeight="bold" color="secondary">
                    List des jeux
                  </Typography>
                  <Button
                    sx={{
                      width: "100px",
                      fontSize: "16px",
                      fontWeight: "400",
                      backgroundColor: "alt.main",
                      textTransform: "none",
                      ":hover": {
                        backgroundColor: "alt[400]",
                      },
                    }}
                    onClick={() => navigate("/games/add")}
                  >
                    Ajouter
                  </Button>
                </Box>
                <Builder
                  builder={() => {
                    if (games && games.length > 0) {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {games.map((game) => (
                            <GameCard
                              key={game._id}
                              game={game}
                              setSelectedGame={setSelectedGame}
                              setOpen={setOpen}
                            />
                          ))}
                        </Box>
                      );
                    } else {
                      return <ErrorText text={"Aucun jeu trouvé!"} />;
                    }
                  }}
                />
              </>
            );
          }
        }}
      />
      <CustomDialog
        content={"Supprimer le jeu"}
        open={open}
        setOpen={setOpen}
        title={"Es-tu sûr ?"}
        onClick={handleDelete}
      />
    </>
  );
};

export default GamesList;
